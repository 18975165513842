<template>
    <div class="modal-status-form-field">
        <div class="modal-status-form-field__title"
            v-if="isTitleNeeded"
            v-html="title"
        />
        <div class="modal-status-form-field__description"
            v-if="isDescriptionNeeded"
            v-html="field.legend"
        />
        <div class="modal-status-form-field__input"
            v-if="inputData"
            >
            <ui-form-field
                v-bind="inputData"
                v-on:update="inputHandler"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import utils from '~/utils';

const bikDependencyErrorCode = 'bikDependencyError';
const lsByBikErrorCode = 'lsByBikError';

export default {
    name: 'modal-status-form-field',
    props: {
        field: {
            type: Object,
        },
    },
    validations() {
        if (this.field.validations === null) {
            return {};
        }
        const result = {
            value: {},
        };
        if (this.field.validations.is) {
            const is = this.field.validations.is;
            if (is.equal !== undefined) {
                result.value.is = () => this.value === is.equal;
            }
        }
        if (this.field.validations.required) {
            result.value.required = () => this.value !== null && this.value !== '' && this.value !== undefined;
        }
        if (this.isBikDependent) {
            result.value[bikDependencyErrorCode] = () => {
                if (!this.getBankInfoResult) {
                    return true;
                }
                return this.value === this.getBankInfoResult[this.field.id];
            };
        }
        if (this.isLs) {
            result.value[lsByBikErrorCode] = () => !this.lsByBikError;
        }
        return result;
    },
    computed: {
        ...mapState('status', [
            'getBankInfoResult',
            'getBankInfoError',
            'bikDependentFieldIds',
        ]),
        ...mapGetters('status', [
            'lsByBikError',
        ]),
        isBikDependent() {
            return this.bikDependentFieldIds.indexOf(this.field.id) > -1;
        },
        isLs() {
            return this.field.id === 'ls';
        },
        errors() {
            const result = {};
            if (this.field.validations) {
                Object.keys(this.field.validations).forEach((key) => {
                    result[key] = this.field.validations[key].error;
                });
            }
            if (this.isBikDependent) {
                result[bikDependencyErrorCode] = 'Данное значение не соответствует банковской информации для указанного БИК';
            }
            if (this.isLs) {
                result[lsByBikErrorCode] = 'Расчетный счет не соответствует банку';
            }
            return result;
        },
        isTitleNeeded() {
            return this.title && this.field.type === 'TextViewField';
        },
        isDescriptionNeeded() {
            return this.field.legend && this.field.type === 'TextViewField';
        },
        isTouched() {
            return this.$store.state.status.isTouched;
        },
        title() {
            const text = this.field.text || this.field.label;
            return text
                ? utils.common.parseBBCode(text, !(this.field.type === 'CheckboxField' || this.field.type === 'TextViewField'))
                : text;
        },
        defaultValue() {
            return this.field.type === 'CheckboxField' ? false : '';
        },
        currentValue() {
            return this.field.value;
        },
        newValue() {
            return this.$store.state.status.newValues[this.field.id];
        },
        value() {
            if (this.newValue !== undefined) {
                return this.newValue;
            }
            if (this.currentValue !== null) {
                return this.currentValue;
            }
            return this.defaultValue;
        },
        inputData() {
            if (this.field.value === 'valueless') {
                return null;
            }
            const result = {
                value: this.value,
                validation: this.$v.value,
                model: {
                    errors: this.errors,
                    label: this.title || this.field.placeholder,
                    value: this.value,
                    isDisabled: this.$v.$invalid ? false : this.field.isDisabled,
                    labelIsHtml: true,
                },
            };
            if (this.field.template === 'radio') {
                result.model.type = 'radio-group';
                result.model.options = this.field.options;
            } else if (this.field.type === 'CheckboxField') {
                result.model.type = 'checkbox';
            } else {
                result.model.type = 'text';
            }
            return result;
        },
        mutations() {
            return this.field.mutateFields;
        },
    },
    methods: {
        inputHandler(value) {
            this.$store.state.status.newValues = {
                ...this.$store.state.status.newValues,
                [this.field.id]: value,
            };
        },
    },
    watch: {
        value: {
            handler(newValue) {
                if (this.mutations) {
                    const relatedMutations = this.mutations.filter((mutation) => mutation.value === newValue);
                    // const newFields = [];
                    relatedMutations.forEach((mutation) => {
                        this.$store.getters['status/currentStep'].fields.forEach((field) => {
                            const { id } = mutation;
                            const { value, ...rest } = mutation.mutate ? mutation.mutate : { visible: !newValue };
                            if (field.id === id) {
                                if (value) {
                                    this.$store.state.status.newValues = {
                                        ...this.$store.state.status.newValues,
                                        [field.id]: value,
                                    };
                                }
                                this.$store.getters['status/currentStep'].fields =
                                    this.$store.getters['status/currentStep'].fields
                                        .map((field) => {
                                            if (field.id === id) {
                                                return {
                                                    ...field,
                                                    ...rest,
                                                };
                                            }
                                            return field;
                                        });
                            }
                        });
                    });
                }
            },
            immediate: true,
        },
        '$v.$invalid': {
            handler(value) {
                this.$emit('validation-update', {
                    id: this.field.id,
                    isValid: !value,
                });
            },
            immediate: true,
        },
        isTouched: {
            handler(value) {
                if (value) {
                    this.$v.$touch();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-status-form-field {
    &__title {
        .typography-body();

        margin-bottom: 8px;
    }
    &__description {
        .typography-body();

        margin-bottom: 8px;
    }
    &__title + &__description {
        margin-top: -8px;
    }
}
</style>
